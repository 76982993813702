import { render, staticRenderFns } from "./CSelectCustomer.vue?vue&type=template&id=52e6aa83&"
import script from "./CSelectCustomer.vue?vue&type=script&lang=ts&"
export * from "./CSelectCustomer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52e6aa83')) {
      api.createRecord('52e6aa83', component.options)
    } else {
      api.reload('52e6aa83', component.options)
    }
    module.hot.accept("./CSelectCustomer.vue?vue&type=template&id=52e6aa83&", function () {
      api.rerender('52e6aa83', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/select-customer/CSelectCustomer.vue"
export default component.exports